<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="ml-5">
        <v-btn @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>Назад</v-btn
        >
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-card width="1000" class="pa-3">
        <div class="d-flex grow flex-wrap">
          <v-sheet
            elevation="6"
            width="100%"
            height="100"
            class="mb-n6 success pa-7 has_heading"
          >
            <div class="text-h5 font-weight-light white--text">
              {{ page_text }}
            </div>
            <div class="subtitle-1 font-weight-light white--text">
              * помечены обязательные поля
            </div>
          </v-sheet>
        </div>

        <v-form
          ref="form"
          v-model="valid"
          autocomplete="off"
          @submit.prevent=""
        >
          <v-container fluid>
            <v-col cols="">
              <v-text-field
                v-model.number="point.point_number"
                label="Номер зарядной станции*"
                min="100000"
                max="999999"
                hint="Выданный станции уникальный номер в системе"
                type="number"
                autocomplete="off"
                :rules="number_rule"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.model"
                autocomplete="off"
                label="Модель*"
                hint="Модель зарядной станции от производителя"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-select
                v-model="point.evse[0].power"
                :items="point_powers"
                label="Мощность станции"
                :rules="[required]"
              ></v-select>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.vendor"
                label="Производитель*"
                hint="Название производителя"
                autocomplete="off"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.name"
                label="Название"
                hint="Удобное название станции"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.serial"
                label="Серийный номер*"
                hint="Серийный номер станции от производителя"
                autocomplete="off"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.address"
                hint="Точный адрес установки зарядной станции"
                autocomplete="off"
                label="Адрес*"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.password"
                type="password"
                label="Пароль*"
                hint="Пароль для зарядной станции"
                autocomplete="off"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                :value="point.max_transactions"
                label="Одновременных зарядок"
                type="number"
                hint="Колличество одновременных зарядок на станции"
                autocomplete="off"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                :value="connectors_count"
                label="Коннекторы"
                type="number"
                hint="Колличество коннекторов на станции"
                autocomplete="off"
                :rules="[required]"
                @input="add_connector"
              ></v-text-field>
            </v-col>
            <template v-for="(connector, i) in this.point.evse[0].connectors">
              <v-col cols="" :key="i">
                <v-select
                  :items="connectors_types"
                  v-model="connector.connector_type"
                  :label="connector_text(i + 1)"
                  autocomplete="off"
                  :rules="[required]"
                ></v-select>
              </v-col>
            </template>

            <v-col cols="">
              <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="yellow"></v-tabs-slider>

                <v-tab v-for="item in tabs" :key="item.id">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item key="map" eager transition="scale-transition">
                  <div ref="googleMap" id="map"></div>
                </v-tab-item>
                <v-tab-item key="gps" transition="scale-transition">
                  <v-card flat>
                    <v-text-field
                      label="Широта*"
                      v-model="point.latitude"
                      :rules="[required]"
                    ></v-text-field>
                    <v-text-field
                      label="Долгота*"
                      v-model="point.longitude"
                      :rules="[required]"
                    ></v-text-field>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-col cols="">
              <v-btn type="submit" block @click="create_point" color="primary"
                >Создать</v-btn
              >
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PointCreate",
  components: {},

  data: () => {
    return {
      valid: true,
      new_point: {
        point_number: null,
        name: "",
        model: "",
        password: "",
        address: "",
        serial: "",
        vendor: "",
        latitude: "",
        longitude: "",
        max_transactions: 1,
        evse: [{ power: "", connectors: [{ connector_type: "type2" }] }],
      },
      connectors_count: 1,
      connectors: 1,
      connectors_types: ["type2", "chademo"],
      point_powers: [
        "7AC",
        "22AC",
        "50DC",
        "80DC",
        "90DC",
        "120DC",
        "150DC",
        "180DC",
      ],
      tab: 1,
      tabs: [
        { id: 1, name: "map" },
        { id: 2, name: "gps" },
      ],
      required: (v) => !!v || "Обязателено для заполнения",
      number_rule: [
        (v) => !!v || "Обязателено для заполнения",
        (v) => (v && v > 100000) || "Номер не может быть меньше 100000",
        (v) => (v && v < 999999) || "Номер не может быть больше 999999",
      ],
    };
  },
  created() {
    this.$store.dispatch("load_point_info", {
      point_id: this.$route.params.point_id,
      query: {},
    });
  },
  mounted() {
    // this.init_map();
    this.connectors_count = this.point.evse[0].connectors.length;
    this.connectors = this.point.evse[0].connectors.length;
  },
  watch: {
    tab(value) {
      if (value === 0) {
        this.init_map();
      }
    },
  },
  computed: {
    page_text() {
      if (this.$route.name === "point_edit") {
        return "Редактирование станции";
      }
      return "Добавить зарядную станцию";
    },
    point() {
      if (this.$route.name === "point_edit") {
        return this.$store.getters.POINT_INFO;
      }
      return this.new_point;
    },
  },
  methods: {
    init_map() {
      // console.log(window.google);
      const myLatlng = { lat: 55.75532732384285, lng: 37.633314549373964 };
      const mapContainer = this.$refs.googleMap;
      const map = new window.google.maps.Map(mapContainer, {
        zoom: 12,
        center: myLatlng,
      });
      // Create the initial InfoWindow.
      let infoWindow = new window.google.maps.InfoWindow({
        content: "Выберите точку на карте",
        position: myLatlng,
      });
      infoWindow.open(map);
      // Configure the click listener.
      map.addListener("click", (mapsMouseEvent) => {
        // Close the current InfoWindow.
        infoWindow.close();
        // Create a new InfoWindow.
        infoWindow = new window.google.maps.InfoWindow({
          position: mapsMouseEvent.latLng,
        });
        const gps = mapsMouseEvent.latLng.toJSON();
        if (this.$route.name == "point_edit") {
          this.$store.getters.POINT_INFO.latitude = gps.lat;
          this.$store.getters.POINT_INFO.longitude = gps.lng;
        } else {
          this.new_point.latitude = gps.lat;
          this.new_point.longitude = gps.lng;
        }
        infoWindow.setContent(
          JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
        );
        infoWindow.open(map);
      });
    },

    validateField() {
      this.$refs.form.validate();
    },

    create_point() {
      this.validateField();

      if (!this.valid) {
        return;
      }
      const point = {
        name: this.point.name,
        model: this.point.model,
        password: this.point.password,
        address: this.point.address,
        serial: this.point.serial,
        vendor: this.point.vendor,
        point_number: this.point.point_number,
        evse: this.point.evse,
        gps: this.point.latitude + " " + this.point.longitude,
        show_app: this.point.show_app,
        max_transactions: this.max_transactions,
      };
      if (this.$route.name === "point_edit") {
        point.id = this.point.id;
        this.$store.dispatch("update_point", point).then(() => {
          this.$router.push({ name: "points_list" });
        });
      } else {
        this.$store.dispatch("create_point", point).then(() => {
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.$router.push({ name: "points_list" });
        });
      }
    },
    connector_text(index) {
      return "Тип коннектора " + index;
    },
    add_connector(new_value) {
      if (this.connectors > new_value) {
        this.point.evse[0].connectors.splice(-1);
      }
      if (this.connectors < new_value) {
        this.point.evse[0].connectors.push({ connector_type: "type2" });
      }
      this.connectors = new_value;
    },
  },
};
</script>

<style scoped lang="scss">
#map {
  height: 800px;
}
.has_heading {
  position: relative;
  top: -35px;
}
</style>
